.about {
  padding: 80px 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  background-image: url(../../assets/Union.svg);
}
.about .title {
  padding-right: 25px;
  position: absolute;
  left: 0;
  width: 550px;
  font-weight: 700;
  font-size: 60px;
  line-height: 34px;
  text-align: end;
  color: #ffffff;
  border-bottom: 10px solid #ff2222;
}

.about .text {
  padding: 130px 130px 0px 130px;
  text-align: left;
  color: #ffffff;
}

.about .text ul {
  padding-left: 40px;
  list-style-type: circle;
}

.about .subtitle {
  margin-bottom: 33px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.about .content {
  margin-bottom: 57px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
@media screen and (max-width: 1024px) {
  .about {
    padding: 74px 30px;
  }

  .about .title {
    padding-right: 10px;
    width: 310px;
    font-size: 40px;
    line-height: 23px;
  }
  .about .text {
    padding: 0;
    padding-top: 100px;
  }
  .about .subtitle {
    margin-bottom: 33px;
    margin-left: 50px;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
  }

  .about .content {
    margin-bottom: 57px;
    font-weight: 400;
    font-size: 18px;
  }
}
