.footer {
  padding: 71px 120px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #282828;
  color: #bdbdbd;
}

.footer .contact-block div {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.footer .contact-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .footer {
    padding: 62px 30px 89px 30px;
    flex-direction: column;
    align-items: center;
    background-color: #282828;
    color: #bdbdbd;
  }
  .footer .contact-block {
    width: 344px;
  }
  .footer .mizhvukhamy-img {
    margin: 0;
    margin-top: 100px;
    margin-bottom: 61px;
  }
}
