.header {
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  border-bottom: 1px solid #4f4f4f;
  z-index: 1;
  overflow: hidden;
}
.header .hamburger-img {
  color: #ffffff;
}
.header .navbar {
  margin: auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 1240px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .nav-menu {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.header {
  padding: 1rem;
  font-weight: 500;
}
.navbar .nav-item a {
  font-style: normal;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  color: #ffffff;
}
.hamburger {
  display: none;
}
.header .nav-item:first-child {
  padding-left: 35px;
  border-bottom: 3px solid #ff2222;
}

@media screen and (max-width: 1024px) {
  .header {
    height: 48px;
    width: 100%;
    max-width: 100%;
  }
  .header .navbar {
    max-width: 100%;
  }
  .hamburger {
    position: absolute;
    right: 1.5rem;
    display: block;
    cursor: pointer;
    z-index: 1000;
  }
  .close {
    position: fixed;
    top: 1rem;
    right: 1.5rem;
  }
  .header .nav-menu {
    position: fixed;
    left: 0;
    top: -100%;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    z-index: 999;
    text-align: center;
    -webkit-transition: all 0.26s ease-in-out;
    -moz-transition: all 0.26s ease-in-out;
    -ms-transition: all 0.26s ease-in-out;
    -o-transition: all 0.26s ease-in-out;
    transition: all 0.26s ease-in-out;
    background-color: #000000;
  }
  .nav-menu.active {
    top: 0px;
    left: 0;
    padding-top: 48px;
    overflow-y: hidden;
  }
  .nav-item {
    margin: 1.5rem 0;
    font-size: 1.2rem;
    line-height: 1.2;
  }
  .header .nav-item:first-child {
    padding: 0;
    border: none;
  }
}
