.news {
  padding: 100px 120px;
}
.news .title {
  padding-right: 21px;
  position: absolute;
  left: 0;
  width: 355px;
  font-weight: 700;
  font-size: 60px;
  line-height: 34px;
  text-align: end;
  color: #000000;
  border-bottom: 10px solid #ff2222;
}

.news .content-wrapper {
  padding-top: 142px;
  display: flex;
}
.news .article {
  margin-right: 33px;
  width: 325px;
}
.news .image {
  margin-bottom: 32px;
  width: 325px;
  height: 200px;
}
.news .article-title {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.news .text {
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.news .date {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #828282;
}

@media screen and (max-width: 1024px) {
  .news {
    padding: 74px 30px;
  }
  .news .title {
    padding-right: 35px;
    width: 208px;
    font-size: 40px;
    line-height: 23px;
  }
  .news .content-wrapper {
    padding-top: 83px;
    flex-direction: column;
    align-items: center;
  }

  .news .article {
    margin-right: 0;
    margin-bottom: 64px;
  }
}
