.download {
  padding: 100px 120px;
}
.download .title {
  padding-right: 21px;
  position: absolute;
  left: 0;
  width: 1024px;
  font-weight: 700;
  font-size: 60px;
  line-height: 34px;
  text-align: end;
  color: #000000;
  border-bottom: 10px solid #ff2222;
}
.download .title-mob {
  display: none;
}
.download .content-wrapper {
  padding-top: 105px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.download .subtitle {
  margin-bottom: 48px;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.download .letters {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}

.download .letters .download-btn {
  margin-left: 23px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #4f4f4f;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .download {
    padding: 74px 30px;
  }
  .download .title {
    padding-right: 35px;
    width: 650px;
    font-size: 40px;
    line-height: 23px;
  }

  .download .content-wrapper {
    padding-top: 140px;
  }
}
@media screen and (max-width: 678px) {
  .download {
    padding: 74px 30px;
  }
  .download .title {
    display: none;
  }
  .download .title-mob {
    display: flex;
    flex-direction: column;
  }
  .download .title-mob .t1 {
    padding-right: 21px;
    position: absolute;
    left: 0;
    width: 312px;
    font-weight: 700;
    font-size: 40px;
    line-height: 23px;
    text-align: end;
    color: #000000;
    border-bottom: 10px solid #ff2222;
  }
  .download .title-mob .t2 {
    margin-top: 48px;
    padding-right: 21px;
    position: absolute;
    left: 0;
    width: 389px;
    font-weight: 700;
    font-size: 40px;
    line-height: 23px;
    text-align: end;
    color: #000000;
    border-bottom: 10px solid #ff2222;
  }
}
