* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Mizhvukhamy';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
.button {
  padding: 18px 46px 17px 56px;
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  background-color: #ff2222;
  border-color: #ff2222;
  cursor: pointer;
  text-align: center;
}
.button:disabled {
  color: #ffffff;
  background-color: #ff6a6a;
  border-color: #ff6a6a;
  cursor: not-allowed;
}
@media screen and (max-width: 678px) {
  .button {
    width: 100%;
  }
}
