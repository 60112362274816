.join {
  padding: 90px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  z-index: 1005;
  overflow-y: auto;
}
.join .close {
  color: #ffffff;
  cursor: pointer;
}
.join .title {
  margin-bottom: 48px;
  font-weight: 700;
  font-size: 60px;
  line-height: 72px;
  color: #ffffff;
}
.join form {
  display: flex;
  flex-direction: column;
}

.join form div {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
}
.join label {
  margin-bottom: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.join input {
  height: 48px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
}
.join textarea {
  height: 111px;
}
.join .checkbox {
  flex-direction: row;
  align-items: center;
}
.checkbox input {
  margin-right: 11px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .join {
    padding: 66px 30px;
  }
  .join .title {
    margin-bottom: 96px;
    font-size: 40px;
    line-height: 48px;
  }
}
